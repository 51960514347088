import React, { useReducer, useContext, useEffect, createContext } from 'react'

import { AUTHENTICATION } from './actions'

const StoreStateContext = createContext()
const StoreDispatchContext = createContext()

const initialState = {
  loggedIn: false,
  showNav: true,
  hideErrorDialog: true,
  errorMessage: '',
  online: true
};

const defaultNavItems = [{ key: '/', name: 'Home', ariaLabel: 'Home', icon: 'Home', url: '#/' }]

function storeReducer(state, action) {
  switch (action.type) {
    case AUTHENTICATION.LOGIN: {
      let navigation = []
      let roles = []
      action.payload.roles.forEach(role => {
        roles.push(role.code)
        const _navigation = JSON.parse(role.navigation)
        _navigation.forEach(itm => {
          if(itm.link && itm.link.length > 0 && typeof itm.link === 'object'){
            const _link = itm.link.reduce((result, item) => {
              if (typeof (item) === 'string') {
                const _module = action.payload.modules.find((module) => { return module.code === item && module.showInMenu === true })
                if (_module) {
                  result.push({ key: `/${item}`, name: _module.name, icon: _module.icon ? _module.icon : 'WebAppBuilderModule', url: `#/${item}` })
                }
              }
              else {
                if (item.module) {
                  const _module = action.payload.modules.find((module) => { return module.code === item.module && module.showInMenu === true })
                  if (_module) {
                    result.push({ key: item.key, name: item.name ?? _module.name, icon: item.icon ?? `${_module.icon ?? 'WebAppBuilderModule'}`, url: item.url ?? `#/${module.code}` })
                  }
                }
                else {
                  result.push({ key: item.key, name: item.name, icon: item.icon ?? 'WebAppBuilderModule', url: item.url })
                }
              }
              return result
            }, [])

            navigation.push({ key: itm.key, name: itm.name, links: _link })
          }
          else{
            if (typeof (itm) === 'string') {
              const _module = action.payload.modules.find((module) => { return module.code === itm && module.showInMenu === true })
              if (_module) {
                navigation.push({ key: `/${itm}`, name: _module.name, icon: _module.icon ? _module.icon : 'WebAppBuilderModule', url: `#/${itm}` })
              }
            }
            else {
              if (itm.module) {
                const _module = action.payload.modules.find((module) => { return module.code === itm.module && module.showInMenu === true })
                if (_module) {
                  navigation.push({ key: itm.key, name: itm.name ?? _module.name, icon: itm.icon ?? `${_module.icon ?? 'WebAppBuilderModule'}`, url: itm.url ?? `#/${module.code}` })
                }
              }
              else {
                navigation.push({ key: itm.key, name: itm.name, icon: itm.icon, url: itm.url })
              }
            }
          }
        })
      })

      let _navItems = [...defaultNavItems]
      action.payload.modules.forEach((_module) => {
        if (_module.showInMenu === true) {
          _navItems.push({ key: `/${_module.code}`, name: _module.name, ariaLabel: _module.name, icon: _module.icon ? _module.icon : 'WebAppBuilderModule', url: `#/${_module.code}` })
        }
      })

      return {
        ...state,
        loggedIn: true,
        user: { ...action.payload.user, tenant: { name: action.payload.profile?.name } },
        roles: [...roles],
        modules: [...action.payload.modules],
        nav: navigation, //_navItems,
      };
    }
    case AUTHENTICATION.LOGOUT:
      return { ...state, loggedIn: false, user: {}, nav: [], modules: [], errorMessage: '', hideErrorDialog: true };
    case 'toggleNav':
      return { ...state, showNav: !state.showNav };
    case 'setErrorMessage': {
      if (state.hideErrorDialog === false) {
        return state
      }

      return { ...state, errorMessage: action.payload, hideErrorDialog: false };
    }
    case 'clearErrorMessage': {
      return { ...state, errorMessage: '', hideErrorDialog: true };
    }
    case 'setOnline': {
      return { ...state, online: action.payload }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(storeReducer, initialState)

  useEffect(() => {
    const controller = new AbortController();
    const _onOnlineStatus = () => {
      dispatch({ type: 'setOnline', payload: navigator.onLine })
    }
    dispatch({ type: 'setOnline', payload: navigator.onLine })
    window.addEventListener('offline', _onOnlineStatus, { signal: controller.signal });
    window.addEventListener('online', _onOnlineStatus, { signal: controller.signal });

    return () => {
      controller.abort();
    }
  }, [])

  return (
    <StoreStateContext.Provider value={state}>
      <StoreDispatchContext.Provider value={dispatch}>
        {children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  )
}

function useStoreState() {
  const context = useContext(StoreStateContext)
  if (context === undefined) {
    throw new Error('useStoreState must be used within a StoreProvider')
  }
  return context
}

function useStoreDispatch() {
  const context = useContext(StoreDispatchContext)
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider')
  }
  return context
}

export { StoreProvider, useStoreState, useStoreDispatch }