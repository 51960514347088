import React, { useState } from 'react'

import { useStoreDispatch } from '../common/storeContext'
import { useMiddletier } from '../common/middletier'
import { AUTHENTICATION } from '../common/actions'

import {
  IconButton,
  Text,
  Stack,
  Persona,
  PersonaSize,
  getTheme,
  CommandBarButton,
  Callout,
  mergeStyleSets,
  FontWeights,
  PersonaInitialsColor,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  TextField
} from '@fluentui/react'

import { useBoolean } from '@fluentui/react-hooks';

const theme = getTheme();

const styles = mergeStyleSets({
  callout: {
    width: 320,
    backgroundColor: 'white',
  },
  button: {
    width: 130,
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: 'block',
    marginTop: 20,
  },
});

// const dragOptions = {
//   moveMenuItemText: 'Move',
//   closeMenuItemText: 'Close',
//   menu: ContextualMenu,
// };

// const modalPropsStyles = { main: { maxWidth: 450 } };
const modalProps = {
  titleAriaId: '',
  subtitleAriaId: '',
  isBlocking: true,
  styles: { main: { maxWidth: 450 } },
  dragOptions: false,
}

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Change Password',
  // subText: 'Do you want to send this message without a subject?',
};

const initialLoginData = { oldPassword: '', newPassword: '', confirmPassword: '' }

const AppHeader = (props) => {
  const updateAppState = useStoreDispatch()
  const { mutation } = useMiddletier()

  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const [hidePasswordDialog, { toggle: toggleHidePasswordDialog }] = useBoolean(true);

  const [loginData, setLoginData] = useState(initialLoginData);
  const [errorMessage, setErrorMessage] = useState('');


  const onClickToggle = () => {
    updateAppState({ type: 'toggleNav' })
  }

  const _onLogout = () => {
    mutation([
      {
        // index: 0,
        method: 'logout',
        params: [],
        attributes: []
      }
    ])
      .then(({ data }) => {
        // console.log('_onLogout: ', data)
        updateAppState({ type: AUTHENTICATION.LOGOUT })
      })
      .catch((error) => {
        // console.error('error: ', error)
        updateAppState({ type: AUTHENTICATION.LOGOUT })
      })
  }

  const onShowPasswordDialog = () => {
    toggleIsCalloutVisible();
    setLoginData(initialLoginData);
    toggleHidePasswordDialog();
  }

  const onChangePassword = () => {
    if (loginData.newPassword !== loginData.confirmPassword) {
      setErrorMessage('The passwords you entered do not match.')
    }
    else if (loginData.newPassword.length < 8) {
      setErrorMessage('Your password must be longer than 7 characters.')
    }
    else {
      mutation([
        {
          // index: 0,
          method: 'setPassword',
          params: [
            {
              code: 'oldPassword',
              value: loginData.oldPassword,
              graphqlType: 'String',
              required: true
            },
            {
              code: 'newPassword',
              value: loginData.newPassword,
              graphqlType: 'String',
              required: true
            }
          ],
          attributes: []
        }
      ])
        .then(({ data }) => {
          // console.log('setPassword: ', data)
          setErrorMessage('');
          toggleHidePasswordDialog();
        })
        .catch((error) => {
          // console.log('error: ', error.message)
          setErrorMessage(error.message)
        })
    }
  }

  return (
    <React.Fragment>
      <header style={{ position: 'relative', height: '48px', lineHeight: '48px', backgroundColor: '#0078D4', color: '#FFFFFF' }}>
        <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
          <div id="headerLeftRegion" style={{ position: 'relative', height: '48px', width: '48px' }}>
            <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0 }}>
              <IconButton iconProps={{ iconName: 'GlobalNavButton' }} title="Emoji" ariaLabel="Emoji" styles={{ root: { height: '100%', width: '48px', lineHeight: '48px', backgroundColor: '#0078D4' }, icon: { fontSize: '16px', height: '48px', lineHeight: '48px', margin: '0px', color: '#FFFFFF' }, rootHovered: { backgroundColor: '#004d88' }, rootPressed: { backgroundColor: '#004d88' } }} onClick={onClickToggle} />
            </div>
          </div>
          <div id="headerCenterRegion" style={{ position: 'relative', height: '48px', flex: 1 }}>
            <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
              <div id="leftRegion" style={{ position: 'relative', width: '200px' }}>
                <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0 }}>
                  <Text nowrap block styles={{ root: { width: '100%', lineHeight: '48px', fontSize: '16px', fontWeight: '600', padding: '0 12px 0 8px', color: theme.palette.white } }}>Unified Data Platform</Text>
                </div>
              </div>
              <div id="centerRegion" style={{ position: 'relative', flex: 1 }}>
                <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0 }}>
                </div>
              </div>
              <div id="headerButtonRegion" style={{ position: 'relative', width: '144px' }}>
                <div style={{ position: 'absolute', overflow: 'hidden', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}>
                  <IconButton iconProps={{ iconName: 'Ringer' }} title="Notification" ariaLabel="Notification" styles={{ root: { height: '100%', width: '48px', lineHeight: '48px', backgroundColor: '#0078D4' }, icon: { fontSize: '16px', height: '48px', lineHeight: '48px', margin: '0px', color: '#FFFFFF' }, rootHovered: { backgroundColor: '#004d88' }, rootPressed: { backgroundColor: '#004d88' } }} />
                  <IconButton iconProps={{ iconName: 'Settings' }} title="Settings" ariaLabel="Settings" styles={{ root: { height: '100%', width: '48px', lineHeight: '48px', backgroundColor: '#0078D4' }, icon: { fontSize: '16px', height: '48px', lineHeight: '48px', margin: '0px', color: '#FFFFFF' }, rootHovered: { backgroundColor: '#004d88' }, rootPressed: { backgroundColor: '#004d88' } }} />
                  <IconButton iconProps={{ iconName: 'Help' }} title="Help" ariaLabel="Help" styles={{ root: { height: '100%', width: '48px', lineHeight: '48px', backgroundColor: '#0078D4' }, icon: { fontSize: '16px', height: '48px', lineHeight: '48px', margin: '0px', color: '#FFFFFF' }, rootHovered: { backgroundColor: '#004d88' }, rootPressed: { backgroundColor: '#004d88' } }} />
                </div>
              </div>
            </div>
          </div>
          <Stack id="headerRightRegion" styles={{ root: { cursor: 'pointer', position: 'relative', height: '48px', width: '48px', selectors: { ':hover': { backgroundColor: theme.palette.themeDarker }, ':hover div.ms-Persona-initials': { backgroundColor: theme.palette.themePrimary, color: theme.palette.themePrimary.white } } } }} onClick={toggleIsCalloutVisible}>
            <Persona styles={{ root: { margin: '8px', padding: '0', width: '32px', height: '32px', selectors: { '&  .ms-Persona-initials': { border: '1px solid' } } } }} initialsColor={PersonaInitialsColor.blue} size={PersonaSize.size32} hidePersonaDetails text={props.user.username} />
          </Stack>
          {isCalloutVisible && (
            <Callout
              className={styles.callout}
              target={'#headerRightRegion'}
              onDismiss={toggleIsCalloutVisible}
              isBeakVisible={false}
              setInitialFocus
            >
              <Stack>
                <Stack styles={{ root: { alignItems: 'center', justifyContent: 'flex-end' } }} horizontal>
                  <Text styles={{ root: { flex: 1, marginLeft: '20px' } }}>{props.user.tenant.name}</Text>
                  <CommandBarButton styles={{ root: { height: '47px' } }} text="Sign Out" onClick={_onLogout} />
                </Stack>
                <Stack styles={{ root: { alignItems: 'center' } }} horizontal>
                  <Persona
                    hidePersonaDetails
                    text={props.user.username}
                    size={PersonaSize.size72}
                    initialsColor={PersonaInitialsColor.blue}
                    styles={{ root: { margin: '12px', width: '88px', height: '88px' } }}
                  />
                  <div style={{ flex: 1, fontSize: '13px', paddingRight: '12px' }}>
                    <div style={{ fontSize: '18px', fontWeight: '700', padding: '1px' }}>{props.user.username}</div>
                    <div style={{ marginTop: '3px', padding: '1px' }}>{props.user.email}</div>
                    {
                      props.user.authenticationType === 'LDAP' ?
                        <></> : <div style={{ marginTop: '4px', padding: '1px', textDecoration: 'underline' }} onClick={onShowPasswordDialog}>Change Password</div>
                    }
                  </div>
                </Stack>
              </Stack>
            </Callout>
          )}
        </div>
      </header>
      <Dialog
        hidden={hidePasswordDialog}
        onDismiss={toggleHidePasswordDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <>
          <TextField
            label="Old password"
            type="password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
            value={loginData.oldPassword}
            onChange={(evt, val) => { setLoginData({ ...loginData, oldPassword: val }); }}
          />
          <TextField
            label="Create new password"
            type="password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
            value={loginData.newPassword}
            onChange={(evt, val) => { setLoginData({ ...loginData, newPassword: val }); }}
          />
          <TextField
            label="Confirm new password"
            type="password"
            canRevealPassword
            revealPasswordAriaLabel="Show password"
            errorMessage={errorMessage}
            value={loginData.confirmPassword}
            onChange={(evt, val) => { setLoginData({ ...loginData, confirmPassword: val }); }}
          />
        </>
        <DialogFooter>
          <PrimaryButton onClick={onChangePassword} text="Submit" />
          <DefaultButton onClick={toggleHidePasswordDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </React.Fragment>
  )
}

export default AppHeader;